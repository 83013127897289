$(document).foundation();

/*open nav mobile menu*/
function openMenu() {
    $("nav").toggleClass("open");
    $("#wrapper").toggleClass("open");
}

function closeMenu() {
    $("nav").removeClass("open");
    $("#wrapper").removeClass("open");
}

$("#open-mobile-menu-trigger").click(openMenu);
$("nav > a").click(closeMenu);
$("#close-mobile-menu-trigger").click(closeMenu);

function getDistanceFromTop() {
    var menuButton = $("#open-mobile-menu-trigger").offset().top;
    var distanceScrolled = $(window).scrollTop();
    if (distanceScrolled > 116) {
        $("#open-mobile-menu-trigger").addClass("setMenuFixed");
    } else {
        $("#open-mobile-menu-trigger").removeClass("setMenuFixed");
    }
}

$(window).scroll(getDistanceFromTop);

//Caption toggle
var captionLocal = $('.caption-local');
captionLocal.parent().click(function(){
    $(this).find(captionLocal).toggleClass('caption-local-hidden');
});

//Vertical center red quotes
$(window).load(function(){
    var quote = $("#appart-quote");
    var height = quote.height();
    var containerHeight = quote.parent().next("div.columns").height();
    var paddingTop = (containerHeight-height)/2;
    quote.css({"padding-top": paddingTop + "px", "padding-bottom": paddingTop + "px"});
});

//NEWS - Get height of top elements
$(window).load(function(){
    $("section").each(function(){
        var topElementsHeight = parseInt(50 + $("h6", this).outerHeight(true) + $("h2", this).outerHeight(true) + $("div.row", this).outerHeight(true) + $("p:first-child", this).outerHeight(true), 10);
        $(this).css("height", topElementsHeight + "px");
    });
});

//Read more buttons
$('p.readmore').click(function(){
    console.log('clicked');
	var prevSection = $(this).prev('section');
    var childrenHeights = [];
	prevSection.children().each(function(){
        childrenHeights.push($(this).outerHeight(true));
    });
	var expandedHeight = childrenHeights.reduce(function(x,y){return x+y;})+50;
	console.log('childrenHeights: '+childrenHeights);
	console.log('expandedHeiht: '+expandedHeight);
	prevSection.animate({
		height: expandedHeight+'px'
	});
    $(this).addClass('hidden');
});

$('p.readless').click(function(){
	var $this = $(this).parents('section');
	var topElementsHeight = parseInt(50 + $this.find('h6').outerHeight(true) + $this.find('h2').outerHeight(true) + $this.find("div.row").outerHeight(true) + $this.find("p:first-child").outerHeight(true));
	console.log('hide + topElementsHeight: '+topElementsHeight);
    $(this).parents('section').animate({height: topElementsHeight+'px'});
    $('p.readmore').removeClass('hidden');
});

//Disclaimer
$('footer ul li:last-child').click(function(){
    $("#disclaimer").toggleClass("active");
});
$("#disclaimer").click(function(){
    $(this).removeClass("active");
})

//Show or hide agents
function agents() {
    var prevPage = document.referrer;
    console.log(prevPage);
}
$(document).ready(agents);